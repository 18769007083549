<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('Ip ')" label-for="ip">
                            <b-form-input id="role-name" v-model="ipFilter" class="form-control"
                                placeholder="Please Enter Ip" />
                        </b-form-group>
                    </b-col>
                    <!-- <b-col cols="12" md="3">
                        <b-form-group :label="$t('Platform')" label-for="department">
                            <v-select v-model="platformFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="platformOptions" :reduce="(val) => val" input-id="department" />
                        </b-form-group>
                    </b-col> -->
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('Device')" label-for="status">
                            <v-select v-model="deviceFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="deviceOptions" input-id="status" :reduce="(val) => val" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group :label="$t('Device Model')" label-for="model">
                            <v-select v-model="deviceModelFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="deviceModelOptions" input-id="model" :reduce="(val) => val" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3" class="w-100 d-flex align-items-center justify-content-end">
                        <b-button variant="outline-secondary" @click="resetFilter()">
                            {{ $t("Reset") }}
                        </b-button>
                        <b-button variant="primary" class="ml-2" @click="fetchAgencyLoginLog()">
                            {{ $t("Search") }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card no-body>
            <b-table ref="refAgencyLoginLogTable" class="position-relative table-white-space mb-0"
                :items="agencyLoginLogList" responsive :fields="tableColumns" primary-key="index" :sort-by.sync="sortBy"
                show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
                <template #cell(index)="data">
                    {{ perPage * (currentPage - 1) + data.index + 1 }}
                </template>

                <template #cell(createdAt)="data">
                    {{
                        data.item.created_at
                        ? formatDateTime(data.item.created_at).replace(".000000Z", "")
                        : "-"
                    }}
                </template>
            </b-table>
            <div class="p-2">
                <b-row>
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                            {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              ">
                        <b-pagination v-model="currentPage" :total-rows="totalAgencyLoginLogList" :per-page="perPage"
                            first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
                            next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
} from "bootstrap-vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import store from "@/store";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import memberStoreModule from "@/views/apps/member/memberStoreModule";
import i18n from "@/libs/i18n";

export default {
    components: {
        BButton,
        BFormGroup,
        BModal,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BTr,
        BTh,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {},
    data() {
        return {
            departmentDatas: null,
        };
    },
    methods: {
    },
    setup() {
        const MARKETING_MODULE_NAME = "marketing";
        if (!store.hasModule(MARKETING_MODULE_NAME))
            store.registerModule(MARKETING_MODULE_NAME, marketingStoreModule);
        if (!store.hasModule("app-member"))
            store.registerModule("app-member", memberStoreModule);
        onUnmounted(() => {
            if (store.hasModule(MARKETING_MODULE_NAME))
                store.unregisterModule(MARKETING_MODULE_NAME);
        });

        const toast = useToast();
        const agencyLoginLogList = ref(null);
        const totalAgencyLoginLogList = ref(0);
        const perPage = ref(25);
        const currentPage = ref(1);
        const perPageOptions = [10, 25, 50, 100];
        const sortBy = ref("id");
        const isSortDirDesc = ref(true);
        const platformOptions = ref([]);
        const deviceOptions = ref([]);
        const deviceModelOptions = ref([]);

        const ipFilter = ref();
        const platformFilter = ref();
        const deviceFilter = ref();
        const deviceModelFilter = ref();

        const refAgencyLoginLogTable = ref(null);

        const dataMeta = computed(() => {
            const localItemsCount = refAgencyLoginLogTable.value
                ? refAgencyLoginLogTable.value.localItems.length
                : 0;
            return {
                from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totalAgencyLoginLogList.value,
            };
        });

        const refetchData = () => {
            refAgencyLoginLogTable.value.refresh();
        };

        watch([currentPage, perPage], () => {
            fetchAgencyLoginLog();
        });


        const tableColumns = [
            { key: "index", label: "#" },
            { key: "agency_username", label: i18n.t("Agency Name") },
            // { key: "agent", label: i18n.t("Agent") },
            { key: "browser", label: i18n.t("Browser") },
            { key: "device_model", label: i18n.t("Device Model") },
            { key: "device_family", label: i18n.t("Device Family") },
            { key: "ip", label: i18n.t("Ip") },
            { key: "location", label: i18n.t("Location") },
            { key: "platform", label: i18n.t("Platform") },
            { key: "platform_name", label: i18n.t("Platform Name") },
            { key: "platform_family", label: i18n.t("Platform Family") },
            { key: "platform_version", label: i18n.t("Platform Version") },
            { key: "createdAt", label: i18n.t("Created at") },
            // { key: "updatedAt", label: i18n.t("Updated at") },
            // { key: "action", label: i18n.t("Actions") },
        ];

        // store.dispatch("app-member/fetchPlatform", {}).then((response) => {
        //     if (response.data.code == "00") {
        //         platformOptions.value = response.data.data;
        //     }
        // });
        store.dispatch("app-member/fetchDevice", {}).then((response) => {
            if (response.data.code == "00") {
                deviceOptions.value = response.data.data;
            }
        });
        store.dispatch("app-member/fetchDeviceModel", {}).then((response) => {
            if (response.data.code == "00") {
                deviceModelOptions.value = response.data.data;
            }
        });

        const fetchAgencyLoginLog = (ctx, callback) => {
            store
                .dispatch("marketing/fetchLoginLog", {
                    perPage: perPage.value,
                    page: currentPage.value,
                    ip: ipFilter.value,
                    platform: platformFilter.value,
                    device: deviceFilter.value,
                    device_model: deviceModelFilter.value,
                })
                .then((response) => {
                    const { loginLogs, total } = response;
                    agencyLoginLogList.value = loginLogs;
                    totalAgencyLoginLogList.value = total;
                })
                .catch(() => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error fetching department list",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                });
        };

        fetchAgencyLoginLog();

        const resetFilter = () => {
            ipFilter.value = "";
            deviceFilter.value = "";
            platformFilter.value = "";
            deviceModelFilter.value = "";
        };

        return {
            tableColumns,
            perPage,
            currentPage,
            totalAgencyLoginLogList,
            dataMeta,
            perPageOptions,
            sortBy,
            isSortDirDesc,
            refAgencyLoginLogTable,
            agencyLoginLogList,
            ipFilter,
            deviceFilter,
            platformFilter,
            deviceModelFilter,

            formatDateTime,

            resetFilter,
            fetchAgencyLoginLog,
            refetchData,
            platformOptions,
            deviceOptions,
            deviceModelOptions,
        };
    },
};
</script>
  